<script setup>
import { WdsPillFloating, WdsPropertyCard, WdsTag } from '@wds/components';

const props = defineProps({
  properties: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const emit = defineEmits(['propertyClick']);

const { useUrl } = useUrls();
const { useImage } = useImages();
const { useRating } = useRatings();

// REF
const galleryList = ref(null);

// DATA
const activeGalleryItem = ref(0);
const galleryBoundaries = ref({
  left: true,
  right: false,
});

// ### METHODS
function getGalleryItemFullWidth() {
  const galleryListRef = toValue(galleryList);
  if (galleryListRef) {
    const galleryFirstCard = galleryListRef.firstElementChild;
    return galleryFirstCard.offsetWidth;
  }

  return null;
}

function handleGalleryScroll(e) {
  const scrollEnd = e.currentTarget.scrollLeft;
  const itemWidth = getGalleryItemFullWidth();
  if (itemWidth) {
    activeGalleryItem.value = Math.floor(scrollEnd / itemWidth);
  }
}

function handleGalleryBoundaries(e) {
  const galleryListRef = toValue(galleryList);
  if (galleryListRef) {
    galleryBoundaries.value.left = e.currentTarget?.scrollLeft === 0;
    galleryBoundaries.value.right
      = e.currentTarget?.scrollLeft === galleryListRef?.scrollWidth - galleryListRef?.offsetWidth;
  }
}

function moveGalleryItem(direction) {
  const validDirection = [1, -1].includes(direction) ? direction : 1;
  const galleryListRef = toValue(galleryList);

  if (
    (validDirection < 0 && activeGalleryItem.value <= 0)
    || (validDirection > 0 && activeGalleryItem.value >= props.properties?.length - 1)
  ) {
    return;
  }

  if (galleryListRef) {
    activeGalleryItem.value += validDirection;
    moveGalleryToActive();
  }
}

function moveGalleryToActive() {
  const galleryListRef = toValue(galleryList);
  if (galleryListRef) {
    const galleryCards = galleryListRef.querySelectorAll('.deal-card');
    const nextCard = galleryCards?.[activeGalleryItem.value];
    if (nextCard) {
      nextCard?.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'center',
      });
    }
  }
}

function getPropertyDetailsForCard(property) {
  const propertyImage = property?.image || null;
  const propSlides = propertyImage ? [useImage.getImage(propertyImage)] : [];
  const propertyUrl = `${useUrl.getPwaLangBaseUrl()}pwa/wds/hosteldetails.php/${property?.urlFriendlyName}/${property?.location?.city?.urlFriendlyName}/${property?.propertyId}/`;
  return {
    name: property?.title,
    href: propertyUrl || property?.link,
    distance: property?.subtitle,
    gallery: {
      slides: propSlides,
      alt: property?.title,
      title: property?.title,
    },
    rating: {
      score: property?.score,
      totalReviews: property?.reviews,
      translations: toValue(useRating.getRatingScoreKeywordTranslations),
    },
  };
}

function onPropertyCardClick(property, index) {
  emit('propertyClick', property, index);
}

onMounted(() => {
  const galleryListRef = toValue(galleryList);
  if (galleryListRef) {
    galleryListRef?.addEventListener('scrollend', handleGalleryScroll, false);
    galleryListRef?.addEventListener('scroll', handleGalleryBoundaries, false);
  }
});

onUnmounted(() => {
  const galleryListRef = toValue(galleryList);
  if (galleryListRef) {
    galleryListRef?.removeEventListener('scrollend', handleGalleryScroll, false);
    galleryListRef?.removeEventListener('scroll', handleGalleryBoundaries, false);
  }
});
</script>

<template>
  <div
    v-if="properties?.length"
    class="cards-container"
  >
    <div class="nav">
      <div class="deals-navigation">
        <WdsPillFloating
          icon-start="arrow-left"
          aria-label="left arrow"
          :disabled="galleryBoundaries.left"
          @click="moveGalleryItem(-1)"
        />
        <WdsPillFloating
          icon-start="arrow-right"
          aria-label="right arrow"
          :disabled="galleryBoundaries.right"
          @click="moveGalleryItem(1)"
        />
      </div>
    </div>

    <div class="popular-deals-wrapper">
      <div
        ref="galleryList"
        class="popular-deals-card-list"
      >
        <WdsPropertyCard
          v-for="(property, index) in properties"
          :key="property.propertyId"
          :show-pricing="false"
          v-bind="getPropertyDetailsForCard(property)"
          class="deal-card"
          @click="onPropertyCardClick(property, index)"
        >
          <template #custom-tags>
            <WdsTag
              class="deal-tag"
              :text="property.tag"
              color="pink"
            />
          </template>
        </WdsPropertyCard>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cards-container {
  .nav {
    display: grid;
    grid-template-columns: 1fr;
    padding-inline: $wds-spacing-m;

    .deals-navigation {
      display: none;
      align-items: center;
      justify-content: flex-end;
      gap: $wds-spacing-m;
    }

    @include desktop {
      .deals-navigation {
        display: flex;
      }
    }
  }

  h1 {
    @include title-3-bld;

    margin-bottom: $wds-spacing-m;

    @include tablet {
      @include title-2-bld;
    }
  }

  .popular-deals-wrapper {
    overflow: hidden;
    width: 100%;

    .popular-deals-card-list {
      display: inline-flex;
      gap: $wds-spacing-m;
      overflow-x: auto;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
      padding: $wds-spacing-m;
      width: 100%;

      @include no-scrollbar;

      .deal-card {
        min-width: wds-rem(260px);
        scroll-snap-align: start;
        scroll-margin-inline: $wds-spacing-m;
      }

      .deal-tag {
        :deep(.tag-text) {
          @include title-6-bld;
        }
      }

      > * {
        flex: 0 0 wds-rem(260px);
        height: auto;
      }
    }
  }

  @include desktop {
    margin-top: wds-rem(-32px);
  }
}
</style>
